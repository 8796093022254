<div class='reply-list-div'>
    <div *ngFor="let reply of replies; trackBy: trackByFn" class='reply-item-div'>
        <app-reply-item [collection]="collection" [docId]="docId" [commentId]="commentId"
                        [reply]="reply">
        </app-reply-item>
    </div>
</div>
<div class='new-reply-div'>
    <div class='reply-span-div'>
        ↳ <span class='reply-span' (click)="toggleReply()" *ngIf="!replyModeService.writeModeIdSet.has(commentId)">
            Reply
        </span>
        <span class='reply-span' (click)="toggleReply()" *ngIf="replyModeService.writeModeIdSet.has(commentId)">
            Hide
        </span>
    </div>
    <div class='reply-input-div' *ngIf="replyModeService.writeModeIdSet.has(commentId)">
        <app-reply-input [collection]="collection" [docId]="docId"
                         [commentId]="commentId" *ngIf="signedIn">
        </app-reply-input>
        <div class='sign-in-message' *ngIf="!signedIn">Please sign in to leave a reply.</div>
    </div>
</div>
