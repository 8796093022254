<div>
    <div class='title-div'>
        Blog
    </div>
    <div class='subtitle-div'>
        Articles of my life events, projects, and everything else!
    </div>
    <div class='blog-home-header-div'>
        <div>
            List by
            <select (change)="changeListByOption($event)">
                <option value="year">Year</option>
                <option value="category">Cateogry</option>
            </select>
        </div>
        <div class='language-div'>
            <span [ngClass]="{'selected-language-span': languageService.language === 'korean'}"
                  class='kor-span language-span' (click)="languageService.chooseLanguage('korean')">
                한국어
            </span>
            <span [ngClass]="{'selected-language-span': languageService.language === 'english'}"
                  class='language-span' (click)="languageService.chooseLanguage('english')">
                English
            </span>
        </div>
    </div>

    <div *ngIf="listByOption === 'year'" class='post-list-div'>
        <div *ngFor="let year of years" class='snippet-group-div'>
            <div class='snippet-group-header-div'>{{year}}</div>
            <div *ngFor="let blogSnippetId of blogSnippetsByYear[year]">
                <div>
                    <app-blog-snippet [language]='languageService.language'
                                      [blogSnippet]='blogSnippets[blogSnippetId]'>
                    </app-blog-snippet>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="listByOption === 'category'" class='post-list-div'>
        <div *ngFor="let category of categories" class='snippet-group-div'>
            <div class='snippet-group-header-div'>{{category}}</div>
            <div *ngFor="let blogSnippetId of blogSnippetsByCategory[category]">
                <div>
                    <app-blog-snippet [language]='languageService.language'
                                      [blogSnippet]='blogSnippets[blogSnippetId]'>
                    </app-blog-snippet>
                </div>
            </div>
        </div>
    </div>
</div>