<div>
    <div>
        <a routerLink="/admin/new-blog-post">New Blog Post</a>
    </div>
    <div>
        <a routerLink="/admin/manage-blog-post">Manage Blog Posts</a>
    </div>
    <div>
        <a routerLink="/admin/new-project">New Project</a>
    </div>
    <div>
        <a routerLink="/admin/manage-projects">Manage Projects</a>
    </div>
</div>