<div class='container-div'>
    <div class='language-div'>
        <span [ngClass]="{'selected-language-span': languageService.language === 'korean'}"
              class='language-span kor-span' (click)="languageService.chooseLanguage('korean')">
            한국어
        </span>
        <span [ngClass]="{'selected-language-span': languageService.language === 'english'}"
              class='language-span' (click)="languageService.chooseLanguage('english')">
            English
        </span>
    </div>
    <div *ngIf="languageService.language !== 'korean'" class='content-div'>
        <h2><strong>Why Noninertial Frame?</strong></h2>
        <p>
            Noninertial frame, by definition, is a frame of reference that moves with the object, 
            so that the moving object appears to violate Newton's laws of motion since it accelerates despite having no horizontal forces on it (Dictionary.com).
        </p>
        <p>
            It is a frame whose velocity is continuously changing and in which classical laws of mechanics do not work.
            It is also the real frame we live or should live in because our world moves every moment.
        </p>
        <p>
            <strong>Noninertial Frame</strong> is to keep ourselves in a frame that accelerates every moment. 
            Acceleration does not necessarily refer to increase of speed; it could be change of direction or even decrease of speed.
        </p>
        <p>
            Every moment, we change the speed or direction, eventually towards the dream each one of us has. 
            The posts in this blog will mainly deal with my projects and display the trace of the professional side of my life.
        </p>
        <p>
            If anyone has any ideas, quesitons, or thoughts, please feel free to contact me (yongkyun.daniel.lee@gmail.com) or leave a comment on any of the posts!
        </p>

        <h2>
            <strong>What is my dream?</strong>
        </h2>
        <p>
            Develop technology or service that makes people happy.
        </p>
        <p>
            Design and build an amusement park or a roller coaster.
        </p>
        <p>
            Enhance the educational system of Korea.
        </p>
    </div>
    <div *ngIf="languageService.language === 'korean'">
        <h2><strong>비관성계(Noninertial Frame)의 의미</strong></h2>
        <p>
            비관성계의 정의는 힘을 받지 않는 물체가 가속 운동을 하는 계(frame of reference)입니다 (출처: 네이버 지식백과). 
            보통 매순간 속도가 변하는 계로 비관성계에서는 뉴턴의 제 1 법칙이 성립하지 않습니다. 
            지구는 항상 자전과 공전을 하기 때문에 우리가 살고 있는 계이기도 합니다.
        </p>
        <p>
            <strong>Noninertial Frame</strong> 블로그는 항상 가속 운동을 하는 삶을 살자는 취지로 만들었습니다. 
            가속 운동은 속력이 증가하는 운동 뿐만 아니라 속력이 감소하는 운동과 속력은 일정하지만 방향이 바뀌는 운동을 포함합니다. 
            매 순간 속력을 조절하거나 방향을 조금씩 바꾸면서 꿈에 가까워지는 삶을 살며 그 모습을 이 블로그에 담고자 합니다.
        </p>
        <p>
            물어보고 싶은 질문이나 공유하고 싶은 아이디어나 생각이 있으면 yongkyun.daniel.lee@gmail.com로 연락주시거나 
            댓글 달아주시길 바랍니다.
        </p>

        <h2>
            <strong>나의 꿈</strong>
        </h2>
        <p>
            사람들을 행복하게 만드는 기술이나 서비스 개발
        </p>
        <p>
            롤러코스터나 놀이 공원 기획
        </p>
        <p>
            한국의 교육 시스템 개선
        </p>
    </div>
    <h2 id='life-update' *ngIf="languageService.language !== 'korean'">
        <strong>What have I been up to?</strong>
    </h2>
    <h2 id='life-update' *ngIf="languageService.language === 'korean'">
        <strong>근황</strong>
    </h2>
    <app-timeline></app-timeline>

    <h2>Website Info</h2>
    <div class='version-div'>
        <div class='version-title-div'>Version 3</div>
        <div class='version-row-div'>
            <div class='item-key-div'>Stack</div>
            <div>Angular 11</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>DevTools</div>
            <div>Visual Studio Code</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Hosting Service</div>
            <div>Firebase Hosting</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Database Service</div>
            <div>Firestore</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Domain Registration</div>
            <div>Namecheap</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Launch Date</div>
            <div>2021.02.28</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Github Repository</div>
            <div><a href="https://github.com/yongkyunlee/noninertialframe.com" target="_blank">Link</a></div>
        </div>
    </div>
    <div class='version-div'>
        <div class='version-title-div'>Version 2</div>
        <div class='version-row-div'>
            <div class='item-key-div'>Stack</div>
            <div>ReactJS</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>DevTools</div>
            <div>Visual Studio Code</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Hosting Service</div>
            <div>Firebase Hosting</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Database Service</div>
            <div>Firebase Realtime Database</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Domain Registration</div>
            <div>Namecheap</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Launch Date</div>
            <div>2018.12.15</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Github Repository</div>
            <div><a href="https://github.com/yongkyunlee/noninertialframe_react" target="_blank">Link</a></div>
        </div>
    </div>
    <div class='version-div'>
        <div class='version-title-div'>Version 1</div>
        <div class='version-row-div'>
            <div class='item-key-div'>Stack</div>
            <div>MongoDB, Node.js, Express.js</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>DevTools</div>
            <div>Cloud9 IDE</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Hosting Service</div>
            <div>Heroku</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Database Service</div>
            <div>Mlab</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Domain Registration</div>
            <div>Namecheap</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Launch Date</div>
            <div>2017.02.25</div>
        </div>
        <div class='version-row-div'>
            <div class='item-key-div'>Github Repository</div>
            <div><a href="https://github.com/yongkyunlee/noninertialframe.github.io" target="_blank">Link</a></div>
        </div>
    </div>
</div>
