<form [formGroup]='replyForm'>
    <input class='reply-input' placeholder="Write your reply here." formControlName='content' [attr.disabled]="isUploading ? '' : null">
    <div class='reply-submit-div'>
        <div class='reply-nickname-div'>
            <span class='nickname-span'>Nickname</span>
            <input class='nickname-input' formControlName='nickname' [attr.disabled]="isUploading ? '' : null">
        </div>
        <button class='reply-button' [disabled]="!replyForm.valid || isUploading" type="submit"
                (click)="submitReply()">
            Reply
        </button>
    </div>
    <div class='error-message-div' *ngIf="errorMessage !== ''">
        {{ errorMessage }}
    </div>
    <div class='error-message-div' *ngIf="replyForm.value.nickname.length > nicknameMaxLength">
        The nickname length must be less than {{ nicknameMaxLength + 1 }} characters.
    </div>
</form>