<div class='container-div'>
    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Feb 2023
        </div>
        <div class='event-description-div'>
            <a href="https://82startups.com/" target="_blank">82 Startup</a> Staff Fellow
            <img class='company-img startup82-img' src='../../../assets/logos/82startup.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2023년 02월
        </div>
        <div class='event-description-div'>
            <a href="https://82startups.com/" target="_blank">82 스타트업</a> Staff Fellow 활동 시작
            <img class='company-img startup82-img' src='../../../assets/logos/82startup.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jul 2022
        </div>
        <div class='event-description-div'>
            Start Full-time @ <a href="https://www.onehouse.ai" target="_blank">Onehouse</a>
            <img class='company-img onehouse-img' src='../../../assets/logos/onehouse.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2022년 07월
        </div>
        <div class='event-description-div'>
            풀타임 시작 @ <a href="https://www.onehouse.ai" target="_blank">Onehouse</a>
            <img class='company-img onehouse-img' src='../../../assets/logos/onehouse.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            May 2022
        </div>
        <div class='event-description-div'>
            Pitch @ 82 Startup Web3 Ideathon
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2022년 05월
        </div>
        <div class='event-description-div'>
            사업 계획 피치 @ 82 Startup Web3 아이디어톤
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Apr 2022
        </div>
        <div class='event-description-div'>
            Presentation @ <a href="https://42seoul.kr/seoul42/main/view" target="_blank">42 Seoul</a> (Topic: Starting career in the US) - Review: 4.95/5
            <img class='company-img innovation-academy-img' src='../../../assets/logos/42seoul.jpg'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2022년 04월
        </div>
        <div class='event-description-div'>
            발표 @ <a href="https://42seoul.kr/seoul42/main/view" target="_blank">42 Seoul</a> (주제: 미국에서 커리어 시작하기) - 리뷰: 4.95/5
            <img class='company-img innovation-academy-img' src='../../../assets/logos/42seoul.jpg'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Nov 2021
        </div>
        <div class='event-description-div'>
            <a href="https://pagenow.io" target="_blank">PageNow</a> Launch 
            <img class='company-img pagenow-img' src='../../../assets/logos/pagenow.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 11월
        </div>
        <div class='event-description-div'>
            <a href="https://pagenow.io" target="_blank">PageNow</a> 출시 
            <img class='company-img pagenow-img' src='../../../assets/logos/pagenow.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jul 2021
        </div>
        <div>
            Start Full-time @ <a href="https://www.goldmansachs.com/" target="_blank">Goldman Sachs</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
        </div>
    </div>   
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 07월
        </div>
        <div>
            풀타임 시작 @ <a href="https://www.goldmansachs.com/" target="_blank">골드만 삭스</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jun 2021
        </div>
        <div class='event-description-div'>
            Caltech Graduation (Bachelor's Degree in CS/BEM) 🎓 
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 06월
        </div>
        <div class='event-description-div'>
            칼텍 학부 (컴퓨터 공학/경영 복수 전공) 졸업 🎓
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Apr 2021
        </div>
        <div class='event-description-div'>
            Hacktech 2021 Mentor
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 04월
        </div>
        <div class='event-description-div'>
            Hacktech 2021 멘토
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Mar 2021
        </div>
        <div class='event-description-div'>
            Head TA of IDS 157 (Statistical Inference 📈 ) with 100+ students enrolled
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 03월
        </div>
        <div class='event-description-div'>
            IDS 157 (통계적 추론 📈 ) 헤드 조교 (100명 이상 수강)
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Feb 2021
        </div>
        <div class='event-description-div'>
            TreeHacks 2021 🌴 (<i>[Neo] Reinvent Social Networking</i> Runner-up
            <fa-icon class="trophy-icon" [icon]="faTrophy" style="color: silver"></fa-icon>)
            <a href="https://devpost.com/software/pagechat" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/TreeHacks%202021%20-%20PageChat" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2021년 02월
        </div>
        <div class='event-description-div'>
            TreeHacks 2021 🌴 (<i>[Neo] 소셜 네트워킹 재발명</i> 부문 2위 수상) 
            <fa-icon class="trophy-icon" [icon]="faTrophy" style="color: silver;"></fa-icon>)
            <a href="https://devpost.com/software/pagechat" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/TreeHacks%202021%20-%20PageChat" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Dec 2020
        </div>
        <div class='event-description-div'>
            Goldman Sachs Incoming Analyst Hackathon (1st Place 
            <fa-icon class="trophy-icon" [icon]="faTrophy"></fa-icon>)
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 12월
        </div>
        <div class='event-description-div'>
            골드만 삭스 입사 예정자 해커톤 (1등 
            <fa-icon class="trophy-icon" [icon]="faTrophy"></fa-icon>)
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Nov 2020
        </div>
        <div>
            MBN 2020 AI Training Data Hackathon (3rd Place 
            <fa-icon class='medal-icon' [icon]="faMedal"></fa-icon>)
            <a href="https://mbn.co.kr/news/economy/4346112" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 11월
        </div>
        <div>
            매경닷컴·KDX 주최 2020 인공지능 학습용 데이터 해커톤 (3등
            <fa-icon class='medal-icon' [icon]="faMedal"></fa-icon>)
            <a href="https://mbn.co.kr/news/economy/4346112" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Sep 2020
        </div>
        <div>
            End of SWE Internship @ <a href="https://www.lablup.com/home" target="_blank">Lablup</a>
            <img class='company-img' src="../../../assets/logos/lablup.png">
            <a href="https://noninertialframe.com/blog/Summer%202020%20-%20Lablup%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 09월
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 종료 @ <a href="https://www.lablup.com/home" target="_blank">래블업</a>
            <img class='company-img' src="../../../assets/logos/lablup.png">
            <a href="https://noninertialframe.com/blog/Summer%202020%20-%20Lablup%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>


    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Aug 2020
        </div>
        <div>
            Start of SWE Internship @ <a href="https://www.lablup.com/home" target="_blank">Lablup</a>
            <img class='company-img' src="../../../assets/logos/lablup.png">
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 08월
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 시작 @ <a href="https://www.lablup.com/home" target="_blank">래블업</a>
            <img class='company-img' src="../../../assets/logos/lablup.png">
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
        </div>
        <div>
            End of SWE Internship @ <a href="https://www.goldmansachs.com/" target="_blank">Goldman Sachs</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
            <a href="https://noninertialframe.com/blog/Summer%202020%20-%20Goldman%20Sachs%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>   
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 종료 @ <a href="https://www.goldmansachs.com/" target="_blank">골드만 삭스</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
            <a href="https://noninertialframe.com/blog/Summer%202020%20-%20Goldman%20Sachs%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>


    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jul 2020
        </div>
        <div>
            Start of SWE Internship @ <a href="https://www.goldmansachs.com/" target="_blank">Goldman Sachs</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 07월
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 시작 @ <a href="https://www.goldmansachs.com/" target="_blank">골드만 삭스</a>
            <img class='company-img gs-img' src='../../../assets/logos/gs.png'>
        </div>
    </div>


    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Mar 2020
        </div>
        <div>
            Hacktech 2020 (Best Use of Esri Technology 
            <fa-icon class="trophy-icon" [icon]="faTrophy"></fa-icon>)
            <a href="https://devpost.com/software/homes-reinvented" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/Hacktech%202020%20-%20homES%20ReInvented" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 03월
        </div>
        <div>
            Hacktech 2020 (Best Use of Esri Technology 
            <fa-icon class="trophy-icon" [icon]="faTrophy"></fa-icon>)
            <a href="https://devpost.com/software/homes-reinvented" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/Hacktech%202020%20-%20homES%20ReInvented" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Feb 2020
        </div>
        <div>
            Citadel Terminal Live: SoCal 2020 (Semi-finalist 
            <fa-icon class="medal-icon" [icon]="faMedal"></fa-icon>)
            <a href="https://www.credential.net/ffab9e53-d3fd-48ef-a09e-2a3aa9544da3#gs.oixrat" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2020년 02월
        </div>
        <div>
            Citadel Terminal Live: SoCal 2020 (Semi-finalist 
            <fa-icon class="medal-icon" [icon]="faMedal"></fa-icon>)
            <a href="https://www.credential.net/ffab9e53-d3fd-48ef-a09e-2a3aa9544da3#gs.oixrat" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
        </div>
        <div>
            TreeHacks 2020 🌴
            <a href="https://devpost.com/software/treehack" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/TreeHacks%202020%20-%20BeHeard" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
        </div>
        <div>
            TreeHacks 2020 🌴
            <a href="https://devpost.com/software/treehack" target="_blank">
                <fa-icon class='link-icon' [icon]=faLink></fa-icon>
            </a>
            <a href="https://noninertialframe.com/blog/TreeHacks%202020%20-%20BeHeard" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Sep 2019
        </div>
        <div>
            End of SWE Internship @ <a href="https://www.onclusive.com/" target="_blank">Onclusive</a>
            <img class='company-img gs-img' src='../../../assets/logos/onclusive.png'>
            <a href="https://noninertialframe.com/blog/Summer%202019%20-%20Onclusive%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2019년 09월
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 종료 @ <a href="https://www.onclusive.com/" target="_blank">Onclusive</a>
            <img class='company-img gs-img' src='../../../assets/logos/onclusive.png'>
            <a href="https://noninertialframe.com/blog/Summer%202019%20-%20Onclusive%20Internship" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jun 2019
        </div>
        <div>
            Start of SWE Internship @ <a href="https://www.onclusive.com/" target="_blank">Onclusive</a>
            <img class='company-img gs-img' src='../../../assets/logos/onclusive.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2019년 06월
        </div>
        <div>
            소프트웨어 엔지니어 인턴십 시작 @ <a href="https://www.onclusive.com/" target="_blank">Onclusive</a>
            <img class='company-img gs-img' src='../../../assets/logos/onclusive.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Sep 2018
        </div>
        <div>
            End of AI Research Internship @ <a href="https://www.clova.ai/" target="_blank">Naver</a>
            <img class='company-img gs-img' src='../../../assets/logos/naver.png'>
            <a href="https://noninertialframe.com/blog/Summer%202018" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2018년 09월
        </div>
        <div>
            인공지능 연구 인턴십 종료 @ <a href="https://www.clova.ai/" target="_blank">네이버</a>
            <img class='company-img gs-img' src='../../../assets/logos/naver.png'>
            <a href="https://noninertialframe.com/blog/Summer%202018" target="_blank">
                <fa-icon class='link-icon' [icon]=faPencil></fa-icon>
            </a>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
            Jun 2018
        </div>
        <div>
            Start of AI Research Internship @ <a href="https://www.clova.ai/" target="_blank">Naver</a>
            <img class='company-img gs-img' src='../../../assets/logos/naver.png'>
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
            2018년 06월
        </div>
        <div>
            인공지능 연구 인턴십 시작 @ <a href="https://www.clova.ai/" target="_blank">네이버</a>
            <img class='company-img gs-img' src='../../../assets/logos/naver.png'>
        </div>
    </div>

    <div class='event-div' *ngIf="languageService.language === 'english'">
        <div class='time-div'>
        </div>
        <div>
            Dischargement from ROK Army 🎉 
        </div>
    </div>
    <div class='event-div' *ngIf="languageService.language === 'korean'">
        <div class='time-div-kor'>
        </div>
        <div>
            대한민국 육군 만기 전역 🎉 
        </div>
    </div>
</div>