<div class='manage-blog-post-div'>
    <mat-form-field>
        <input type="text" placeholder="Blog post title" [formControl]="searchControl"
               matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto='matAutocomplete'>
            <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    
    <ng-container *ngIf="searchControl.value === null">
        <div *ngFor="let blogPost of blogPosts" class='blog-post-div'>
            <div class='title-div'>
                <strong><a [routerLink]="'/admin/manage-blog-post/' + blogPost.titleEng">
                    {{ blogPost.titleEng }}
                </a></strong>
            </div>
            <div class='date-div'>
                {{ blogPost.date }}
            </div>
        </div>
    </ng-container>

    <div *ngFor="let blogPost of filteredBlogPosts$ | async" class='blog-post-div'>
        <div class='title-div'>
            <strong><a [routerLink]="'/admin/manage-blog-post/' + blogPost.titleEng">
                {{ blogPost.titleEng }}
            </a></strong>
        </div>
        <div class='date-div'>
            {{ blogPost.date }}
        </div>        
    </div>
</div>